
    import DateUtilities from "@/util/dates";
    import { defineComponent, PropType } from "vue";

    export class DateDataModel {
        date: Date | undefined;
        year: number | undefined;
        month: number | undefined;
        day: number | undefined;
        hours: number | undefined;
        minutes: number | undefined;
        seconds: number | undefined;

        componentsSet(date: Date | undefined, showTime: boolean, showSeconds: boolean) {
            if (date) {
                this.date = date;                
                this.year = date.getFullYear();
                this.month = date.getMonth() + 1;
                this.day = date.getDate();

                if (showTime) {
                    this.hours = date.getHours();
                    this.minutes = date.getMinutes();

                    if (showSeconds) {
                        this.seconds = date.getSeconds();
                    } else {
                        this.seconds = undefined;
                    }
                }                
            } else {
                this.date = undefined;
                this.year = undefined;
                this.month = undefined;
                this.day = undefined;
                this.hours = undefined;
                this.minutes = undefined;
                this.seconds = undefined;
            }
        }

        dateUpdate(showTime:boolean, showSeconds: boolean) {
            const requiredValues = [this.year, this.month, this.day];
            if (showTime) {
                requiredValues.push(...[this.hours, this.minutes]);
                if (showSeconds) {
                    requiredValues.push(this.seconds);
                }
            }

            if (requiredValues.findIndex(c => typeof(c) === "undefined" || isNaN(parseFloat(c.toString()))) > -1) {
                this.date = undefined;
            } else {
                this.date = new Date(this.year!, this.month! - 1, this.day, this.hours ?? 0, this.minutes ?? 0, this.seconds ?? 0);
            }            
        }

        componentsUndefine(showTime:boolean, showSeconds: boolean) {
            this.date = undefined;
            this.year = undefined;
            this.month = undefined;
            this.day = undefined;            

            if (showTime) {
                this.hours = undefined;
                this.minutes = undefined;

                if (showSeconds) {
                    this.seconds = undefined;
                } else {
                    this.seconds = 0;
                }   
            } else {
                this.hours = 0;
                this.minutes = 0;
            }                     
        }
    }

    export interface DateTimeEditorInterface {
        dateChanged: () => void;
    }

    const DateTimeEditor = defineComponent({
        props: {
            modelValue: {
                type: Date as PropType<Date | any | undefined>,
                required: false,
                default: undefined
            },
            enableUndefined: {
                type: Boolean,
                default: true
            },
            minYear: {
                type: Number,
                required: false,
                default: 2020
            },
            maxYear: {
                type: Number,
                required: false,
                default: 2050
            },
            showButtons: {
                type: Boolean,
                required: false,
                default: true
            },
            showTime: {
                type: Boolean,
                default: false
            },
            showSeconds: {
                type: Boolean,
                default: false
            },
            showDayOfWeek: {
                type: Boolean,
                default: false
            }
        },
        data() {           
            const dateData = new DateDataModel();            
            dateData.componentsSet(this.modelValue, this.showTime, this.showSeconds);

            return { 
                dates: DateUtilities,
                dateData 
            };
        },        
        computed: {
            years() {
                const years: number[] = [];
                for (let y = this.minYear; y <= this.maxYear; y++) {
                    years.push(y);
                }
                return years;
            },
            months() {
                const months: number[] = [];
                for (let m = 1; m <= 12; m++) {
                    months.push(m);
                }
                return months;
            },
            days() {
                const days: [number, string][] = [];
                let maxDays = 31;
                let hasYearMonth = typeof(this.dateData.year) !== "undefined" && typeof(this.dateData.month) !== "undefined";
                if (hasYearMonth) {                    
                    maxDays = new Date(this.dateData.year!, this.dateData.month! /* WE NEED M BASE 0 + 1, THIS IS ALREADY BASE + 1 */, 0).getDate();
                }              
                
                for (let d = 1; d <= maxDays; d++) {
                    let dayName = "";
                    if (hasYearMonth) {
                        dayName = this.dates.dayOfWeekAbbr(new Date(this.dateData.year!, this.dateData.month! - 1, d).getDay());
                    }
                    days.push([d, dayName]);
                }
                return days;
            },
            dateComponents(): (number | undefined)[] {
                return [this.dateData.year, this.dateData.month, this.dateData.day, this.dateData.hours, this.dateData.minutes, this.dateData.seconds];
            }
        },
        methods: {
            dateChanged() {
                this.dateData.dateUpdate(this.showTime, this.showSeconds);
                this.$emit("update:modelValue", this.dateData.date);
            }
        },
        watch: {
            modelValue(newValue: Date | undefined) {               
                if (typeof(this.dateData.date) !== typeof(newValue) || this.dateData.date?.toUTCString() !== newValue?.toUTCString()) {
                    this.dateData.componentsSet(newValue, this.showTime, this.showSeconds);
                }
            },
            days(newValue: number[]) {
                if (typeof(this.dateData.day) !== "undefined" && newValue.length < this.dateData.day) {
                    this.dateData.day = newValue.length;
                    this.dateChanged();
                }
            }
        }
    });
    export default DateTimeEditor;
