
	import { defineComponent } from "@vue/runtime-core";
	import TimeSpanEditor from "../controls/date-time/TimeSpanEditor.vue";
	import DialogBase from "./DialogBase.vue";

	interface DialogConfirmData {
		title: string,
		message: string,
		defaultValue: number
	}

	export default defineComponent({
		extends: DialogBase,		
		components: {
			TimeSpanEditor
		},
		data() {
			const dialogDataTyped = this.dialogDataGet<DialogConfirmData>();
			return {
				dialogDataTyped,
				value: dialogDataTyped.defaultValue
			}
		},
		methods: {
			ok() {
				this.returnOk(this.value);
			},
			cancel() {
				this.returnCancel();
			}
		}
	});
