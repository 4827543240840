import UrlUtilities from "./url";
import Authorization, { Auth } from "@/security/Auth";
import { ErrorAction, ResumableAction } from './types';
import GenericDoResultModel from "@/models/shared/GenericDoResultModel";

interface FetchRequestOptions {
    method: string;
    mode: "cors" | "no-cors";
    cache: "no-cache" | "default";
    headers: { [prop: string]: string };
    [prop: string]: unknown;
}

export class Fetch {
    public static request(url: string, data: any, method: "GET" | "POST", formData: boolean, authorization: string | undefined): Promise<Response> {
        let finalUrl = url;

        const options: FetchRequestOptions = {
            method: method,
            mode: "cors",
            cache: "no-cache",
            headers: {}
        }

        if (data) {
            if (method === "GET") {
                finalUrl += UrlUtilities.objectToQuery(data);            
            } else  {
                if (formData) {
                    const formData = new FormData();
                    this.objectToFormData(formData, data);
                    options.body = formData;
                } else {
                    options.body = JSON.stringify(data);
                    options.headers["Content-Type"] = "application/json";     
                }                           
            }
        }    
        
        if (authorization) {
            options.headers["Authorization"] = "Bearer " + authorization;
        }

        return fetch(finalUrl, options);
    }

    public static objectToFormData(formData: FormData, data: any, prefix = "") {
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                this.objectToFormData(formData, data[i], prefix + `[${i}]`)
            }
        } else if (typeof(data) === "object" && data !== null && !this.isFileOrBlob(data)) {
            for (const prop in data) {
                this.objectToFormData(formData, data[prop], prefix.length > 0 ? `${prefix}.${prop}` : prop);
            }
        } else if (typeof(data) !== "undefined") {
            formData.append(prefix, data);
        }
    }

    public static isFileOrBlob(obj: any) {
        if ('File' in window && obj instanceof File) return true;
        if ('Blob' in window && obj instanceof Blob) return true;
        return false;
    }

    public static get(url: string, data: unknown | undefined = undefined, authorization: string | undefined = undefined): Promise<Response> {
        return Fetch.request(url, data, "GET", false, authorization);
    }

    public static post(url: string, data: unknown | undefined = undefined, authorization: string | undefined = undefined): Promise<Response> {
        return Fetch.request(url, data, "POST", false, authorization);
    }
}

export type FetchCallback = (result: unknown) => void;

interface SecureFetchOptions {
    onUnauthorized: ResumableAction;
    onError: ErrorAction;
}

export class SecureFetchContext {
    private static instance: SecureFetchContext;

    public static init(options: SecureFetchOptions | undefined): SecureFetchContext {
        if (!SecureFetchContext.instance) {
            SecureFetchContext.instance = new SecureFetchContext(options);
        }
        return SecureFetchContext.instance;
    }

    public options: SecureFetchOptions | undefined;
  
    private constructor(options: SecureFetchOptions | undefined) {
        this.options = options;
    }

    public configure(options: SecureFetchOptions) {
        this.options = options;
    }

    public request<T>(url: string, data: unknown, method: "GET" | "POST", formData: boolean) {
        return new Promise<T>((resolve) => {
            Fetch.request(url, data, method, formData, Authorization.token).then(r => {
                if (r.status === 200) {                  
                    r.json().then(b => resolve(b));
                    return;                   
                } else if (r.status === 401 || r.status === 403) {
                    if (this.options?.onUnauthorized) {
                        //AFTER AUTHORIZATION PASSED, WE RESOLVE THE INITIAL PROMISE
                        this.options?.onUnauthorized(() => this.request<T>(url, data, method, formData).then(s => resolve(s)));                        
                        return;
                    }   
                } else {
                    this.options?.onError(r.toString());
                }
            }).catch(r => {
                console.log(r);
                this.options?.onError(r.toString());
            });
        });    
    }

    public async requestDoResult<T = any>(url: string, data: unknown, method: "GET" | "POST", formData: boolean) {
        const r = await this.request<GenericDoResultModel<T>>(url, data, method, formData);
        if (!r.success) {
            this.options?.onError(r.errorMessage!);
        }
        return r;
    }

    public get<T>(url: string, data?: unknown) {
        return this.request<T>(url, data, "GET", false);
    }

    public async getDoResult<T = any>(url: string, data?: unknown) {
        return await this.requestDoResult<T>(url, data, "GET", false);
    }

    public post<T>(url: string, data?: unknown) {
        return this.request<T>(url, data, "POST", false);
    }

    public async postDoResult<T = any>(url: string, data?: unknown) {
        return await this.requestDoResult<T>(url, data, "POST", false);
    }

    public postFormData<T>(url: string, data?: unknown) {
        return this.request<T>(url, data, "POST", true);
    }

    public async postFormDataDoResult<T>(url: string, data?: unknown) {
        return await this.requestDoResult<T>(url, data, "POST", true);
    }

    public openURL(url: string, data?: any) {
        let finalUrl = url;
        data = data ?? {};

        const query = { access_token: Auth.token };
        finalUrl += UrlUtilities.objectToQuery(query);

        const form = document.createElement("form");
        form.action = finalUrl;
        form.method = "POST";
        form.target = "_blank";
    
        if (data !== undefined) {
            const hid = document.createElement("input");
            hid.type = "hidden";
            hid.name = "jsonData";
            hid.value = JSON.stringify(data);
            form.appendChild(hid);
        }
        
        document.body.append(form);
        form.submit();
        document.body.removeChild(form);
    }

    public securedURL(url: string, queryData: any) {
        queryData = queryData ?? {};
        Object.assign(queryData, { access_token: Auth.token });
        url += UrlUtilities.objectToQuery(queryData);
        return url;
    }
}

export const SecureFetch = SecureFetchContext.init(undefined);