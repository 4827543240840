import Cookies from "@/util/cookies";
import { Fetch, SecureFetch } from "@/util/ajax";
import ProfileManager from "@/state/ProfileManager";
import UserModel from "@/models/profile/UserModel";
import RoleModel from "@/models/profile/RoleModel";
import { ErrorAction, SimpleAction } from '@/util/types';
import Callbacks from '@/util/callbacks';

export enum LoginStatus
{
    NoAttempt = 0,
    Success = 1,
    ErrorWrongUserNameOrPassword = 2,
    ErrorWrongUserKey = 3,
    ErrorOther = 4
}

export interface TokenResponse {
    status: LoginStatus;
    errorOtherDetail: string;
    token: string | undefined;
    expires: string | Date | undefined;
}

export interface AuthenticateParameters {
    userName?: string,
    password?: string,
    key?: string
}

export class MembershipStatus {
    user: UserModel;   
    roles: RoleModel[];

    constructor(membershipStatusData: any) {
        this.user = ProfileManager.user(membershipStatusData.user);
        this.roles = membershipStatusData.roles.map((r: any) => ProfileManager.role(r));
    }
}

export enum RoleNames {
    Developers = "Developers",
    Administrators = "Administrators",
    Employees = "Employees",
    Payroll = "Payroll",
    Managers = "Managers"
}

export class AuthContext {
    private static instance: AuthContext;

    private options: AuthContextOptions;  

    public isInitialized = false;

    public isAuthenticated = false;

    public isAdmin = false;

    public token: string | undefined;

    public userName: string | undefined;

    public user: UserModel | undefined;
   
    public roles: RoleModel[] | undefined;

    public rolesLowered: string[] | undefined;

    public adminRolesLowered = [
        RoleNames.Administrators.toLowerCase()
    ];   

    public static instantiate(): AuthContext {
        if (!AuthContext.instance) {
            AuthContext.instance = new AuthContext();
        }
        return AuthContext.instance;
    }

    private constructor() {
        this.options = {  } as AuthContextOptions; 
    }

    public configure(options: AuthContextOptions): Promise<boolean> {
        this.options = options;
        this.token = Cookies.cookieGet(this.options.cookieName)?.Value;
        if (window.location.href.indexOf("?showToken") > -1) {
            prompt("Your token is:", this.token);
        }
        return this.authorize();        
    }

    public authenticate(parameters: AuthenticateParameters): Promise<TokenResponse> 
    {
        const promise = new Promise<TokenResponse>((resolve) => {
            Fetch.post(this.options.rootEndPoint + "Token", parameters, undefined)
                 .then(r => r.json())
                 .then(r => {
                    const response = r as TokenResponse;
                    if (response.status === LoginStatus.Success) {
                        this.token = response.token;                        
                        const expires = new Date(response.expires!);
                        Cookies.cookieSet(this.options.cookieName, this.token!, expires);

                        this.authorize().then(() => {                        
                            resolve(response);                        
                        });    
                    } else {
                        resolve(response);
                    }             
                 })                 
                 .catch(r => {                    
                    const response: TokenResponse = {
                        status: LoginStatus.ErrorOther,
                        errorOtherDetail: (r ? r.toString() : "Unknown Error"),
                        token: undefined,
                        expires: undefined
                    } 
                    resolve(response);
                 });
        });
        
        return promise;
    }

    public waitForAuth() {
        return new Promise<boolean>((resolve) => {
            if (this.isAuthenticated) {
                resolve(true);
            } else {
                Callbacks.register("auth", () => resolve(true));
            }
        });        
    }

    public authorize(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.token) {
                SecureFetch.get<unknown>(this.options.rootEndPoint + "MembershipStatus", undefined)
                           .then(r => new MembershipStatus(r))
                           .then(r => {         
                               this.user = r.user;
                               this.roles = r.roles;
                               this.rolesLowered = r.roles.map(r => r.roleName.trim().toLowerCase());                        
                               
                               this.isAdmin = this.adminRolesLowered.findIndex(r => this.rolesLowered && this.rolesLowered.indexOf(r) > -1) > -1;
                               this.isAuthenticated = true;
                               this.isInitialized = true;
                               
                               resolve(this.isAuthenticated);
                               Callbacks.callback("auth");
                           })
                           .catch(r => {
                               this.options.onError(r);
                           });
            } else {
                resolve(this.isAuthenticated);
            }
        });
    }

    public logout() {
        this.token = undefined;
        this.userName = undefined;
        this.roles?.splice(0, this.roles?.length);
        this.rolesLowered?.splice(0, this.rolesLowered?.length);
        this.isAuthenticated = false;
        Cookies.cookieDelete(this.options.cookieName);

        if (this.options?.afterLogout) {
            this.options?.afterLogout();
        }
    }

    public afterLoginExecute() {
        if (this.options?.afterLogin) {
            this.options?.afterLogin();
        }
    }

    public checkAuthorization(...authorizedRoles: (RoleNames | undefined)[]) {
        if (!authorizedRoles || authorizedRoles.length === 0) {
            return true;
        }
        
        if (!this.rolesLowered){
            return false;
        }

        const authorizedRolesLowered = authorizedRoles.map(r => (r ?? "").trim().toLowerCase());
        for (let r = 0; r < authorizedRolesLowered.length; r++) {
            if (this.rolesLowered?.indexOf(authorizedRolesLowered[r]) > -1) {
                return true;
            }
        }
        return false;
    }
}

interface AuthContextOptions {
    rootEndPoint: string;
    cookieName: string;
    afterLogin: SimpleAction;
    afterLogout: SimpleAction;
    afterRequireLogin: SimpleAction;
    onError: ErrorAction;
}

export interface Login {
    userName: string;
    password: string;
}

export const Auth = AuthContext.instantiate();
export default Auth;