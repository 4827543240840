
    import { defineComponent } from "@vue/runtime-core";
    import { PropType } from "vue";
    import { Validatable } from "@/validation/Validatable";    

    export default defineComponent({
        props: {
            modelValue: {
                type: Object as PropType<Validatable>,
                required: true
            },
            validationTitle: {
                type: String,
                required: false
            }
        },
        data() {            
            return {
               
            }
        }
    })
