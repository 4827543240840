export default class UrlUtilities {
    public static objectToQuery(...objects: any[]) {
        let query = "";

        objects.forEach(o => {
            for (const key in o as any) {
                if (Object.prototype.hasOwnProperty.call(o, key)) {
                    if (o[key] === undefined || o[key] === null) {
                        continue;
                    }

                    if (query.length === 0) {
                        query += "?";
                    } else {
                        query += "&";
                    }
                    query += `${encodeURIComponent(key)}=${encodeURIComponent(o[key])}`;
                }
            }
        });

        return query;
    }
}