
	import DialogManager from "@/state/DialogManager";
	import { defineComponent } from 'vue'

	export default defineComponent({
		data() {
			return {
				dialogs: DialogManager
			};
		}
	})
