import RoleModel from "@/models/profile/RoleModel";
import UserModel from "@/models/profile/UserModel";

export class ProfileManagerContext {
    private static instance: ProfileManagerContext;
   
    public static instantiate() {
        if (!this.instance) {
            this.instance = new ProfileManagerContext();
        }
        return this.instance;
    }
    
    users: UserModel[];
    roles: RoleModel[];

    private constructor() {
        this.users = [];
        this.roles = [];
    }

    user(userData: any): UserModel {
        let userRecord = this.users.find(u => u.userID === userData.userID);
        if (!userRecord) {
            userRecord = new UserModel(userData);
            this.users.push(userRecord);
        } else {
            if (userData.displayName) {
                userRecord.displayName = userData.displayName;
            }
        }

        return userRecord;
    }   

    role(roleData: any): RoleModel {
        let roleRecord = this.roles.find(r => r.roleID === roleData.roleID);
        if (!roleRecord) {
            roleRecord = new RoleModel(roleData);
            this.roles.push(roleRecord);
        } else {
            roleRecord.roleName = roleData.roleName;
        }

        return roleRecord;
    }
}

export const ProfileManager = ProfileManagerContext.instantiate();
export default ProfileManager;