import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "input-row"
}
const _hoisted_2 = { class: "input-pair whole" }
const _hoisted_3 = { class: "input-cell" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { id: "vidScanner" }
const _hoisted_6 = {
  key: 1,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    definition: _ctx.definition,
    "max-width": "400px",
    "max-body-height": "400px",
    "show-title": _ctx.dialogDataTyped && _ctx.dialogDataTyped.showTitle
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.dialogDataTyped.title), 1)
    ]),
    body: _withCtx(() => [
      (_ctx.selectedCameraID)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCameraID) = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameras, (camera, c) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: camera.id,
                      key: c
                    }, _toDisplayString(camera.label), 9, _hoisted_4))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _ctx.selectedCameraID]
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("video", _hoisted_5, null, 512), [
        [_vShow, !_ctx.errorMessage]
      ]),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("a", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.cancel();})
      }, "Cancel")
    ]),
    _: 1
  }, 8, ["definition", "show-title"]))
}