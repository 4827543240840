
	import { defineComponent, PropType } from "@vue/runtime-core";
	import { DialogDefinition, DialogState } from "@/state/DialogManager";

	export const DialogWindow = defineComponent({
		props: {
			definition: {
				type: Object as PropType<DialogDefinition>,
				required: true
			},
			showTitle: {
				type: Boolean,
				default: true
			},
			minWidth: {
				type: String,
				required: false
			},
			maxWidth: {
				type: String,
				required: false
			},
			minHeight: {
				type: String,
				required: false
			},
			minBodyHeight: {
				type: String,
				required: false
			},			
			maxHeight: {
				type: Number,
				required: false
			},
			maxBodyHeight: {
				type: String,
				required: false
			},
			noPadding: {
				type: Boolean,
				default: false
			},
			flex: {
				type: Boolean,
				default: false
			},
		},
		data() {
			const viewPortHeightCompensation = 40;
			return {
				viewPortHeightCompensation,
				viewPortHeight: window.innerHeight
			}
		},
		created() {
			window.addEventListener("resize", () => {
				this.viewPortHeight = window.innerHeight;
			});
		},
		computed: {
			cssClass() {
				let cssClass = "";
				switch (this.definition.state) {
					case DialogState.Opening:
						cssClass = "opening";
						break;
					case DialogState.Open:
						cssClass =  "open";
						break;
					case DialogState.Closing:
						cssClass =  "closing";
						break;
					case DialogState.Waiting:
						cssClass =  "waiting";
						break;
				}

				if (this.noPadding) {
					cssClass += " no-padding"
				}

				if (this.flex) {
					cssClass += " flex";
				}

				return cssClass;
			},
			maxHeightComputed() {
				const maxFit = this.viewPortHeight - this.viewPortHeightCompensation;				
				if (this.maxHeight === undefined) {
					return `${maxFit}px`;
				}

				return `${Math.min(maxFit, this.maxHeight)}px`;
			}
		}
	});

	export default DialogWindow;
