
	import { defineComponent, PropType } from "@vue/runtime-core";	
	import { LogicBlock } from "./LogicModel";

	export default defineComponent({
		props: {
			block: {
				type: Object as PropType<LogicBlock>,
				required: true
			},
			blockParent: {
				type: Object as PropType<LogicBlock>,
				required: true
			}
		},
		computed: {
			conditionLeadIn() {
				const blockIndex = this.blockParent.blocks.indexOf(this.block);
				if (blockIndex > 0 && this.blockParent.blocks[blockIndex - 1].conditions.length > 0) {
					if (this.block.conditions.length === 0) {
						return "ELSE"
					} else {
						return "ELSE IF"
					}
				} else if (this.block.conditions.length > 0) {
					return "IF";
				} else {
					return "";
				}
			}
		}
	});
