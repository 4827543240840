
	import { defineComponent } from "@vue/runtime-core";
	import DialogBase from "./DialogBase.vue";

	interface DialogConfirmData {
		title: string,
		message: string
	}

	export default defineComponent({
		extends: DialogBase,		
		data() {
			const dialogDataTyped = this.dialogDataGet<DialogConfirmData>();
			return {
				dialogDataTyped
			}
		}
	});
