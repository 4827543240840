import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DefaultView from "@/views/default/DefaultView.vue";
import LoginView from "@/views/security/LoginView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'default',
    component: DefaultView
  },
  { 
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/tracking",
    name: "tracking",
    component: () => import(/* webpackChunkName: "operations" */ "../views/tracking/TrackingView.vue")        
  },
  { 
    path: "/employee",
    name: "employee",
    component: () => import(/* webpackChunkName: "employee" */ "../views/employee/OperatorPanelView.vue")    
  },
  { 
    path: "/management",
    name: "management",
    component: () => import(/* webpackChunkName: "management" */ "../views/management/_ManagementView.vue"),
    children: [
      {
        path: "",
        name: "management-default",
        redirect() {
          return { name: "daily-validation" };
        },
      },
      {
        path: "daily-validation",
        name: "daily-validation",
        component: () => import(/* webpackChunkName: "operations" */ "../views/management/ManagementDailyValidationView.vue")        
      },
      {
        path: "employee-entries",
        name: "employee-entries",
        component: () => import(/* webpackChunkName: "operations" */ "../views/management/ManagementEmployeeEntriesView.vue")
      },
      {
        path: "manage-operations",
        name: "manage-operations",
        component: () => import(/* webpackChunkName: "operations" */ "../views/management/ManagementOperationsView.vue")
      },
      {
        path: "order-items",
        name: "order-items",
        component: () => import(/* webpackChunkName: "operations" */ "../views/management/ManagementOrderItemsView.vue") 
      }
    ]
  },
  { 
    path: "/operations",
    name: "operations",
    component: () => import(/* webpackChunkName: "operations" */ "../views/operations/_OperationsView.vue"),
    children: [
      {
        path: "",
        name: "operations-default",
        redirect() {
          return { name: "work-centers" };
        },
      },
      {
        path: "work-centers",
        name: "work-centers",
        component: () => import(/* webpackChunkName: "operations" */ "../views/operations/WorkCentersView.vue")        
      },
      {
        path: "parts",
        name: "parts",
        component: () => import(/* webpackChunkName: "operations" */ "../views/operations/PartsView.vue")        
      },
      {
        path: "part-attributes",
        name: "part-attributes",
        component: () => import(/* webpackChunkName: "operations" */ "../views/operations/PartAttributesView.vue")        
      },
      {
        path: "predefined-tasks",
        name: "predefined-tasks",
        component: () => import(/* webpackChunkName: "operations" */ "../views/operations/PredefinedTasksView.vue")        
      },
      {
        path: "planning",
        name: "planning",
        component: () => import(/* webpackChunkName: "operations" */ "../views/operations/PlanningView.vue")        
      }      
    ]
  },
  { 
    path: "/payroll",
    name: "payroll",
    component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/_PayrollView.vue"),
    children: [
      {
        path: "",
        name: "payroll-default",
        redirect() {
          return { name: "payroll-weekly" };
        },
      },
      {
        path: "payroll-weekly",
        name: "payroll-weekly",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/PayrollWeeklyView.vue")
      },
      {
        path: "payroll-recalculate-view",
        name: "payroll-recalculate-view",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/PayrollRecalculateView.vue")
      },
      {
        path: "employee-classes",
        name: "employee-classes",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeClassesView.vue")        
      },
      {
        path: "employee-schedule-shifts",
        name: "employee-schedule-shifts",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeScheduleShiftsView.vue")        
      },
      {
        path: "employee-schedule-breaks",
        name: "employee-schedule-breaks",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeScheduleBreaksView.vue")        
      },      
      {
        path: "employee-late-penalty-rules",
        name: "employee-late-penalty-rules",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeLatePenaltyRulesView.vue")        
      },      
      {
        path: "employee-time-benefit-rules",
        name: "employee-time-benefit-rules",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeTimeBenefitRulesView.vue")        
      },
      {
        path: "employee-custom-adjustment-rules",
        name: "employee-custom-adjustment-rules",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeCustomAdjustmentRulesView.vue")        
      },
      {
        path: "employee-absences",
        name: "employee-absences",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeAbsencesView.vue")        
      },
      {
        path: "employee-absences-bank",
        name: "employee-absences-bank",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/EmployeeAbsencesBankView.vue")        
      },
      {
        path: "custom-flags",
        name: "custom-flags",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/CustomFlagsView.vue")        
      },  
      {
        path: "statutory-holidays",
        name: "statutory-holidays",
        component: () => import(/* webpackChunkName: "payroll" */ "../views/payroll/StatutoryHolidaysView.vue")        
      }
    ]
  },
  { 
    path: "/membership",
    name: "membership",
    component: () => import(/* webpackChunkName: "membership" */ "../views/membership/_MembershipView.vue"),
    children: [
      {
        path: "",
        name: "membership-default",
        redirect() {
          return { name: "users" };
        },
      },
      {
        path: "users",
        name: "users",
        component: () => import(/* webpackChunkName: "membership" */ "../views/membership/UsersView.vue")        
      },
      {
        path: "roles",
        name: "roles",
        component: () => import(/* webpackChunkName: "membership" */ "../views/membership/RolesView.vue")        
      },
      {
        path: "user-attributes",
        name: "user-attributes",
        component: () => import(/* webpackChunkName: "membership" */ "../views/membership/UserAttributesView.vue")        
      }
    ]
  },
  { 
    path: "/configuration",
    name: "configuration",
    component: () => import(/* webpackChunkName: "configuration" */ "../views/configuration/_ConfigurationView.vue"),
    children: [
      {
        path: "",
        name: "configuration-default",
        redirect() {
          return { name: "general" };
        },
      },
      {
        path: "general",
        name: "general",
        component: () => import(/* webpackChunkName: "configuration" */ "../views/configuration/ConfigGeneralView.vue")        
      }
    ]
  },
  { 
    path: "/reporting",
    name: "reporting",
    component: () => import(/* webpackChunkName: "reporting" */ "../views/reporting/ReportingView.vue")
  },
  { 
    path: "/background-tasks",
    name: "background-tasks",
    component: () => import(/* webpackChunkName: "reporting" */ "../views/background-tasks/BackgroundTasksView.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router