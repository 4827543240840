export function copyPropertiesFrom(target: any, source: any, exclude?: string[]) {
	for (const prop in source) {
		if (!exclude || exclude.indexOf(prop) === -1) {
			//AUTO PARSE FIELDS INTENDED AS DATES
			if (source[prop] === null) {
				target[prop] = undefined;
			} else if (prop.toLowerCase().indexOf("date") > -1 && typeof(source[prop]) === "string") {
				target[prop] = new Date(source[prop]);
			} else {
				target[prop] = source[prop];
			}			
		}		
	}
}

export function typify<T = any>(obj: any) {
	const target = { };
	copyPropertiesFrom(target, obj);
	return target as T;
}