
	import { defineComponent } from "@vue/runtime-core";

	interface ScannerListenerData {
		tmoKeyStrokes: number;
		shiftKey: boolean;
		keyStrokes: string;
		codeEnteringBound: ((ev: KeyboardEvent) => void) | undefined;
	}

	export const ScannerListener = defineComponent({
		render: () => "",
		emits: ["codeScanned"],
		data(): ScannerListenerData {
			return {
				tmoKeyStrokes: -1,
				shiftKey: false,
				keyStrokes: "",
				codeEnteringBound: undefined
			}
		},
		mounted() {
			this.codeEnteringBound = this.codeEntering.bind(this);
			window.addEventListener("keydown", this.codeEnteringBound); 
		},
		unmounted() {
			window.removeEventListener("keydown", this.codeEnteringBound!);
		},
		methods: {
			codeEntering(ev: KeyboardEvent) {
				clearTimeout(this.tmoKeyStrokes);

				if (this.keyStrokes.length > 0) {
					//PREVENT FORM SUBMISSIONS
					document.querySelectorAll("input").forEach(i => i.blur());
				}

				if (ev.key) {
					const key = ev.key.toLowerCase();
					if (key === "enter") {
						const text = this.keyStrokes.replaceAll("¿", "=");
						if (text.length > 0) {
							this.$emit("codeScanned", text);
							console.log(`Code Scanned: ${text}`)
						}			
					} else if (key !== "shift") {
						this.keyStrokes += ev.shiftKey ? ev.key.toUpperCase() : ev.key;		
					}
				}
				
				this.tmoKeyStrokes = setTimeout(() => {
					this.keyStrokes = "";
				}, 10);
			}
		}
	});
	export default ScannerListener;
