
    import { defineComponent } from "@vue/runtime-core";

    export const IconInline = defineComponent({
        props: {
            icon: {
                type: String,
                required: false
            },
            mimeType: {
                type: String,
                required: false
            }
        },
        computed: {
            iconName() {
                if (this.icon !== undefined) return this.icon;
                if (this.mimeType === undefined) return "";

                const parts = this.mimeType.split("/");
                if (parts.length < 2) return "";

                switch (parts[0])
                {
                    case "image":
                        return "fa-file-image";
                    case "video":
                        return "fa-file-video";
                    case "application":
                        switch (parts[1])
                        {
                            case "plain":
                            case "rtf":
                            case "msword":
                            case "vnd.openxmlformats-officedocument.wordprocessingml.document":
                            case "vnd.oasis.opendocument.text":
                                return "fa-file-word";
                            case "csv":
                                return "fa-file-excel";
                            case "pdf":
                                return "fa-file-pdf";
                            case "zip":
                            case "x-freearc":
                            case "x-bzip":
                            case "x-bzip2":
                            case "gzip":
                            case "vnd.rar":
                            case "x-tar":
                            case "x-7z-compressed":
                                return "fa-file-zipper";
                        }
                        break;
                }
                return "";
            }
        }
    });
    export default IconInline;
