
	import { defineComponent, PropType } from "@vue/runtime-core";
	import { LogicConditionContainer, LogicCondition, LogicRelationalOperator, LogicBooleanOperator } from "./LogicModel";

	export default defineComponent({
		props: {
			model: {
				type: Object as PropType<LogicConditionContainer>,
				required: true
			}
		},
		data() {
			return {
				container: this.model
			}
		},
		methods: {
			conditionAdd(container: LogicConditionContainer) {				
				container.conditions.push({
					conditions: [],
					booleanOperator: LogicBooleanOperator.AND,
					expressionA: "a",
					expressionB: "b",
					relationalOperator: LogicRelationalOperator.EqualTo
				})
			},
			conditionRemove(condition: LogicCondition) {
				const conditionIndex = this.container.conditions.indexOf(condition);
				if (conditionIndex > - 1) {
					this.container.conditions.splice(conditionIndex, 1);
				}
			}
		}
	});
