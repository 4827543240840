import { Fetch } from "./util/ajax";

export class SettingsContext {
    private static instance: SettingsContext;

    public static instantiate() {
        if (!this.instance) {
            this.instance = new SettingsContext();
        }
        return this.instance;
    }

    apiRoot: string;
    appVersion: string;
    requireSSL: boolean;
    authCookieName: string;
    lastUpdate: Date;

    constructor() {
        this.appVersion = process.env.VUE_APP_VERSION!;
        this.requireSSL = (process.env.VUE_APP_REQUIRE_SSL === "1");
        this.authCookieName = process.env.VUE_APP_AUTH_COOKIE_NAME;
        this.lastUpdate = new Date(process.env.VUE_APP_LAST_UPDATE);
    }

    async initialize() {
        return Fetch.get("/appsettings.json")
            .then(r => r.json())
            .then(r => {
                this.apiRoot = r.apiRoot;
            });
    }
}

const Settings = SettingsContext.instantiate();
export default Settings;