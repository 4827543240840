
	import IconInline from "@/components/icon-library/IconInline.vue";
	import ScannerDialog from "@/components/scanner/ScannerDialog.vue";
	import ScannerListener from "@/components/scanner/ScannerListener.vue";
	import ValidationSummary from "@/components/validation/ValidationSummary.vue";
	import LoginModel from "@/models/login/LoginModel";
	import Auth, { LoginStatus } from "@/security/Auth";
	import DialogManager, { DialogState } from "@/state/DialogManager";
	import { defineComponent } from "@vue/runtime-core";

	export const LoginView = defineComponent({
		components: {
			ValidationSummary,
			ScannerListener,
			IconInline
		},
		data() {
			return {
				auth: Auth,
				dialogs: DialogManager,
				state: DialogState.Open,
				model: new LoginModel()				
			}
		},
		mounted() {
			setTimeout(() => {
				this.state = DialogState.Open;
			});
		},
		computed: {
			cssClass() {
				switch (this.state) {
					case DialogState.Opening:
						return "opening";
					case DialogState.Open:
						return "open";
					case DialogState.Closing:
						return "closing";
					case DialogState.Waiting:
						return "waiting";
				}
				return "";
			}
		},		
		methods: {
			wait() {
				this.state = DialogState.Waiting;
			},
			go() {
				this.state = DialogState.Open;
			},
			scanCode() {
				this.dialogs.add(ScannerDialog, {
					ok: (code: string) => {
						this.codeScanned(code);
					}
				}, { title: "Scan User QR" });
			},
			codeScanned(code: string) {				
				this.model.userName = undefined;
				this.model.password = undefined;
				this.model.key = code;
				this.submit();
			},
			submit() {
				this.model.validate().then(valid => {
					if (valid) {
						this.wait();
						this.auth.authenticate({ userName: this.model.userName, password: this.model.password, key: this.model.key }).then(r => {
							if (r.status === LoginStatus.ErrorWrongUserNameOrPassword) {
								this.model.validationErrors.push("Wrong user name and / or password.");
								this.model.isValid = false;
								this.go();
							} else if (r.status === LoginStatus.ErrorWrongUserKey) {
								this.model.validationErrors.push("Invalid user key or QR code.");
								this.model.isValid = false;
								this.go();
							} else if (r.status === LoginStatus.ErrorOther) {
								this.model.validationErrors.push(r.errorOtherDetail);
								this.model.isValid = false;
								this.go();
							} else {
								this.auth.afterLoginExecute();
							}
						});
					}
				});
			}
		}
	});
	export default LoginView;
