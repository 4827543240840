export interface LogicConditionContainer {
	conditions: LogicCondition[];
	booleanOperator: LogicBooleanOperator;
}

export interface LogicBlock extends LogicConditionContainer {	
	blocks: LogicBlock[];
	return?: string;
}

export interface LogicCondition extends LogicConditionContainer {
	expressionA?: string;
	expressionB?: string;
	relationalOperator: LogicRelationalOperator;
}

export enum LogicBooleanOperator {
	AND = 0,
	OR = 10
}

export enum LogicRelationalOperator {
	LessThan = 0,
	GreaterThan = 10,
	LessThanOrEqualTo = 20,
    GreaterThanOrEqualTo = 30,
    EqualTo = 40,
    NotEqualTo = 50,
    Contains = 60,
    DoesNotContain = 70,
	AnyItemContains = 80,
	NoItemContains = 90
}